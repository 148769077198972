<template>
  <v-container fluid>
    <!-- Título y filtro -->
    <v-row align="center" class="px-4 pt-2 pb-2">
      <v-col cols="6">
        <h2 class="primary--text font-weight-bold" style="font-size: 24px">
          {{ user?.Negocio ? user.Negocio?.nombre : "-" }}
        </h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <pat-select
          :items="categoriesFilter"
          v-model="selectedCategory"
          outlined
          dense
          label=""
        />
      </v-col>
    </v-row>

    <!-- Categorías -->
    <div>
      <h3
        class="mt-2 pb-3 px-4 font-weight-bold black--text mb-3"
        style="font-size: 18px"
      >
        Categorías
      </h3>

      <v-row class="bgcards">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              v-for="(category, index) in categories"
              :key="index"
              cols="4"
              class="text-center mb-3 d-flex flex-column align-center justify-center"
              style="height: 140px; width: 120px"
            >
              <v-card
                class="d-flex align-center justify-center"
                elevation="0"
                style="width: 80px; height: 80px; overflow: hidden"
              >
                <v-img
                  :src="category.image"
                  class="mx-auto"
                  contain
                  style="width: 100%; height: 100%; object-fit: contain"
                />
              </v-card>
              <div
                class="font-weight-medium mt-2"
                style="font-size: 14px; text-align: center"
              >
                {{ category.name }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- Posible banner -->
    <v-row class="blue lighten-3 py-13"> </v-row>

    <!-- Productos más vendidos -->
    <div>
      <h3
        class="mt-8 pb-3 px-4 font-weight-bold black--text mb-3"
        style="font-size: 18px"
      >
        Lo más vendido en PAT
      </h3>
      <v-row class="bgcards">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              v-for="(product, index) in popularProducts"
              :key="index"
              cols="4"
              class="text-center mb-2 d-flex flex-column align-center justify-center px-1 pt-4"
            >
              <v-card
                class="d-flex flex-column align-center justify-center"
                elevation="1"
                style="width: 120px; height: 160px; position: relative"
              >
                <!-- Texto de descuento flotante en la parte superior -->
                <div
                  v-if="product.discount"
                  class="white--text font-weight-bold deep-purple lighten-2"
                  style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 10px;
                    padding: 4px 8px;
                    width: 100%;
                    z-index: 4;
                  "
                >
                  {{ product.discount }}% de descuento
                </div>

                <!-- Imagen del producto -->
                <v-img
                  :src="product.image"
                  contain
                  style="
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                    margin-top: 25px;
                    z-index: 2;
                  "
                />

                <!-- Texto del producto -->
                <v-card-text class="py-0 mt-1">
                  <p
                    class="text-start font-weight-bold"
                    style="
                      margin: 0;
                      padding: 0;
                      line-height: 1.2;
                      font-size: 12px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ product.name }}
                  </p>
                  <p
                    class="text-start"
                    style="
                      margin: 0;
                      padding: 0;
                      line-height: 1.2;
                      font-size: 12px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ product.description }}
                  </p>
                  <p
                    class="text-start primary--text m-0"
                    style="
                      line-height: 1.2;
                      font-size: 12px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    ${{ product.price }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- Modal Comenzar Inventario -->
    <div class="text-center">
      <v-dialog
        v-model="dialogAddProducts"
        max-width="400"
        persistent
        overlay-class="custom-dialog-overlay"
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card
            class="rounded-xl pa-6"
            style="
              background-color: #f4f4fa;
              position: relative;
              overflow: visible;
            "
          >
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: 0px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeAddProducts"
            >
              <v-icon color="primary ">mdi-close</v-icon>
            </v-btn>

            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
              "
            >
              ¡Bienvenido a la Comunidad PAT!
            </v-card-title>

            <v-card-text class="text-center text-body-1 black--text"
              >Para comenzar a vender, necesitas tener productos en tu
              inventario, comienza a cargar productos ahora.</v-card-text
            >
            <v-card-actions class="justify-center">
              <v-btn
                @click="redirectToNewProduct"
                class="py-6"
                color="grey primary"
                dark
                block
                rounded
                >Comenzar inventario</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
    <v-bottom-navigation
      v-model="value"
      app
      background-color="#454854"
      color="#D8FF1D"
      class="rounded-t-xxl"
      dark
      height="82"
      mandatory
    >
      <v-btn value="inicio">
        <span>Inicio</span>

        <v-icon large>mdi-home-outline</v-icon>
      </v-btn>

      <v-btn value="inventario">
        <span>Inventario</span>

        <v-icon large>mdi-format-list-bulleted</v-icon>
      </v-btn>

      <v-btn value="vender">
        <span>Vender</span>

        <v-icon large>mdi-barcode-scan</v-icon>
      </v-btn>

      <v-btn value="promociones">
        <span>Promociones</span>

        <v-icon large>mdi-percent-outline</v-icon>
      </v-btn>

      <v-btn value="comprar">
        <span>Comprar</span>

        <v-icon large>mdi-cart-plus</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { useUserStore } from "../stores/userStore";

export default {
  data() {
    return {
      userStore: useUserStore(),
      value: "inicio",
      dialogAddProducts: true,
      categoriesFilter: [
        "Todas",
        "Medicamento",
        "Botiquín",
        "Vitaminas",
        "Dermatológico",
        "Alimentos",
        "Belleza",
      ],
      selectedCategory: "Todas",
      categories: [
        { name: "Medicamento", image: require("@/assets/advil.png") },
        { name: "Botiquín", image: require("@/assets/curitas.png") },
        { name: "Vitaminas", image: require("@/assets/vitaminas.png") },
        { name: "Dermatológico", image: require("@/assets/dermatologico.png") },
        { name: "Alimentos", image: require("@/assets/alimentos.png") },
        { name: "Belleza", image: require("@/assets/belleza.png") },
      ],
      popularProducts: [
        {
          name: "Advil",
          price: 300,
          description: "Ibuprofeno",
          image: require("@/assets/advil.png"),
          discount: 15,
        },
        {
          name: "Gerber",
          price: 16.5,
          description: "Paracetamol",
          image: require("@/assets/gerber.png"),
        },
        {
          name: "Tylenol",
          price: 198,
          description: "Paracetamol",
          image: require("@/assets/tylenol.png"),
          discount: 10,
        },
        {
          name: "Gerber",
          price: 16.5,
          description: "Paracetamol",
          image: require("@/assets/gerber.png"),
        },
        {
          name: "Tylenol",
          price: 198,
          description: "Paracetamol",
          image: require("@/assets/tylenol.png"),
          discount: 10,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.userStore.user; // Accede al dato 'user' desde la store
    },
  },
  methods: {
    closeAddProducts() {
      this.dialogAddProducts = false;
    },
    redirectToNewProduct() {
      this.$router.push({ name: "new-product" });
    },
  },
  created() {
    const userStore = useUserStore();
    userStore.getUser(); // Llama a la acción de la store
  },
};
</script>
<style>
/* Deshabilita el overflow del modal */
.custom-dialog-content {
  overflow: visible !important;
}
.v-bottom-navigation .v-btn .v-btn__content {
  justify-content: start !important;
  margin-top: 10px !important;
}
</style>
